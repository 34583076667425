'use client'

import { fetchGetAdsFilters } from "@/services/api";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function HomeSearchSection() {
    const router = useRouter();
    const { handleSubmit } = useForm();
    const [filters, setFilters] = useState({ isLoading: true, brands: [], models: [], yearsModel: [], transmissions: [], fuels: [] });

    useEffect(() => {
        if (filters.isLoading) {
            fetchGetAdsFilters({ condition: null })
                .then(res => {
                    if (res) { setFilters({ isLoading: false, ...res }); }
                })
                .catch(() => { setFilters({ ...filters, isLoading: false }); });
        }
        else if (!filters.isLoading && filters.brands.length > 0) {
            if ($('#ddlBrand').length > 0) $('#ddlBrand').niceSelect();
            if ($('#ddlTransmission').length > 0) $('#ddlTransmission').niceSelect();
            if ($('#ddlFuel').length > 0) $('#ddlFuel').niceSelect();
        }
    }, [filters]);

    const onSubmit = () => {
        const ddlBrand = $('#ddlBrand').niceSelect()[0].value;
        const ddlTransmission = $('#ddlTransmission').niceSelect()[0].value;
        const ddlFuel = $('#ddlFuel').niceSelect()[0].value;
        let query = {};
        if (ddlBrand) query.marca = ddlBrand; else delete query.marca;
        if (ddlTransmission) query.cambio = ddlTransmission; else delete query.cambio;
        if (ddlFuel) query.combustivel = ddlFuel; else delete query.combustivel;
        router.push(`/estoque?${new URLSearchParams(query)}`);
    }

    return (
        <section id="filter_form2">
            <div className="auto-container">
                <div className="search-home">
                    <div className="row">
                        <div className="col-md-12 theme-mb-10">
                            <div className="site-heading text-left">
                                <h2>PESQUISAR VEÍCULOS</h2>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix d-block w-100"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 theme-mb-20">
                                        <div className="form-group">
                                            <i className="mdi mdi-menu"></i>
                                            <select id="ddlBrand">
                                                <option value="">Selecione a Marca</option>
                                                {filters.brands.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 theme-mb-20">
                                        <div className="form-group">
                                            <i className="mdi mdi-cogs"></i>
                                            <select id="ddlTransmission">
                                                <option value="">Selecione o Câmbio</option>
                                                {filters.transmissions.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 theme-mb-20">
                                        <div className="form-group">
                                            <i className="mdi mdi-gas-station"></i>
                                            <select id="ddlFuel">
                                                <option value="">Selecione o Combustível</option>
                                                {filters.fuels.map((item, index) => <option value={item.name} key={index}>{item.name.toUpperCase()}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                                        <button type="submit" name="SendPostFormSearch" value="SendPostFormSearch" className="btn btn-orange btn-arrow theme-mb-10 theme-mr-10 btn-full-mobile">
                                            <span>PESQUISAR <i className="mdi mdi-magnify"></i></span>
                                        </button>
                                        <a className="btn btn-dblue btn-arrow theme-mb-10 btn-full-mobile" href="/estoque" title="Ver Estoque">
                                            <span>ESTOQUE COMPLETO <i className="mdi mdi-car"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}