import Image from "next/image";
import { useEffect, useState } from "react"

export const FallBackImage = ({ src, alt, ...rest }) => {
    const [imgSrc, setImgSrc] = useState(src);
    useEffect(() => { setImgSrc(src); }, [src]);

    return (
        <Image
            {...rest}
            alt={alt}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            src={imgSrc ? imgSrc : '/images/no-image.jpeg'}
            onError={() => { setImgSrc('/images/no-image.jpeg') }} />
    )
}