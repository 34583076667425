'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export default function HomeTestimonialSection() {
    // return (
    //     <section className=" theme-pt-40 theme-pb-40 theme-pl-30 theme-pr-30">
    //         <div className="auto-container">
    //             <div className="row">
    //                 <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-30">
    //                     <div className="site-heading text-left">
    //                         <h2>DEPOIMENTOS</h2>
    //                         <p>Opinião de alguns de nossos clientes</p>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="clearfix"></div>
    //             <div className="row">
    //                 <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mr-auto ml-auto">
    //                     <Swiper
    //                         id='testimonial'
    //                         modules={[Navigation]}
    //                         autoplay={true}
    //                         spaceBetween={30}
    //                         loop={true}
    //                     >
    //                         <div className='swiper-wrapper'>
    //                             <SwiperSlide className='testimonial-item'>
    //                                 <div className="tcontent">
    //                                     <blockquote>
    //                                         <p>Adorei a loja , ótimos carros. Negociei um carro por telefone e a transparência nas informações impressiona. Recomendo muito!!</p>
    //                                     </blockquote>
    //                                     <div className="testimonial-arrow-down"></div>
    //                                     <div className="testimonial-author">
    //                                         <p><strong className="font-weight-extra-bold">Walter Barbosa</strong></p>
    //                                     </div>
    //                                 </div>
    //                             </SwiperSlide>
    //                             <SwiperSlide className='testimonial-item'>
    //                                 <div className="tcontent">
    //                                     <blockquote>
    //                                         <p>Adorei a loja , ótimos carros. Negociei um carro por telefone e a transparência nas informações impressiona. Recomendo muito!!</p>
    //                                     </blockquote>
    //                                     <div className="testimonial-arrow-down"></div>
    //                                     <div className="testimonial-author">
    //                                         <p><strong className="font-weight-extra-bold">Walter Barbosa</strong></p>
    //                                     </div>
    //                                 </div>
    //                             </SwiperSlide>
    //                             <SwiperSlide className='testimonial-item'>
    //                                 <div className="tcontent">
    //                                     <blockquote>
    //                                         <p>Adorei a loja , ótimos carros. Negociei um carro por telefone e a transparência nas informações impressiona. Recomendo muito!!</p>
    //                                     </blockquote>
    //                                     <div className="testimonial-arrow-down"></div>
    //                                     <div className="testimonial-author">
    //                                         <p><strong className="font-weight-extra-bold">Walter Barbosa</strong></p>
    //                                     </div>
    //                                 </div>
    //                             </SwiperSlide>
    //                         </div>
    //                     </Swiper>
    //                 </div>
    //             </div>
    //         </div>
    //     </section>
    // );
}